import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, BlogHeader, CategoryNav, PostGrid, Section, FooterCta, SearchBar } from 'components';
import * as styles from './insights.module.scss';

const Blog = () => {
  const blogQuery = graphql`
    query {
      ...BlogQuery
    }
  `;

  const {
    insightsData: { data: insightData },
    newsletterCta: { data: ctaData = {} },
    categories: { nodes: categoryArray },
    allPosts: { nodes: postsArray },
  } = useStaticQuery(blogQuery);

  const {
    insights_title: title,
    insights_tagline: tagline,
    insights_meta_title: metaTitle,
    insights_meta_description: metaDesc,
    insights_subtitle: description,
  } = insightData;

  const seo = {
    title: metaTitle?.text || 'Insights | PAC Capital',
    desc: metaDesc?.text,
  };

  const categoryPosts = [];

  categoryArray.forEach((category, i) => {
    const {
      uid: categoryUid,
      data: { category_name: categoryName },
    } = category;
    const posts = postsArray.filter((post) => post.data.category.uid === categoryUid).slice(0, 3);
    categoryPosts.push({ title: categoryName?.text, posts, categoryLink: categoryUid });
  });

  return (
    <div className={styles.blog}>
      <Layout seo={seo}>
        {/* HEADER */}
        <BlogHeader title={title} tagline={tagline} description={description} />
        {/* RECENT AND POPULAR */}
        <CategoryNav />
        {/* REST OF GRID */}
        <Section className={styles.bottomGrid} largeContainer>
          {categoryPosts.map((item) => {
            const { title: gridTitle, posts: gridPosts, categoryLink } = item;
            if (!gridPosts || gridPosts.length < 1) return null;
            return (
              <Fragment key={gridTitle.text}>
                <PostGrid
                  posts={gridPosts}
                  title={gridTitle}
                  categoryLink={categoryLink}
                  className={`blog-lp-post-grid ${styles.postGrid}`}
                />
              </Fragment>
            );
          })}
        </Section>
        <FooterCta />
      </Layout>
    </div>
  );
};
export default Blog;
